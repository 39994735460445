// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
// import { FormControl, InputLabel, Select, MenuItem, Card, CardMedia, CardContent, Typography, Button, Grid, Box, Snackbar, Chip, IconButton, } from "@mui/material";
// import Visibility from "@mui/icons-material/Visibility";
// import { Add, Remove, Close } from "@mui/icons-material";
// import services from "./apiServices";
// import ProductImage from "./Productimage";

// export default function Shop() {
//     const location = useLocation();
//     const query = new URLSearchParams(location.search);
//     const [shopData, setShopData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [selectedCategory, setSelectedCategory] = useState(
//         query.get("category") || ""
//     );
//     const [categories, setCategories] = useState([]);
//     const [vendors, setVendors] = useState([]);
//     const [selectedVendor, setSelectedVendor] = useState("");
//     const [isLoggedIn, setIsLoggedIn] = useState(false);
//     const [successMessage, setSuccessMessage] = useState("");
//     const [quantities, setQuantities] = useState({}); // State to track individual product quantities
//     const [darkMode, setDarkMode] = useState(false);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const [categoriesResponse, vendorsResponse, productsResponse, loginResponse] =
//                     await Promise.all([
//                         services.getCategories(),
//                         services.getVendors(),
//                         services.getAllProducts(),
//                         services.checkLoginStatus(),
//                     ]);

//                 // Extract available category IDs from the products
//                 const availableCategoryIds = new Set(productsResponse.map(product => product.PROD_TYP_ID));

//                 // Filter categories to include only those that have available products
//                 const filteredCategories = categoriesResponse.filter(category =>
//                     availableCategoryIds.has(category.ATTRIB_CD)
//                 );

//                 setCategories(filteredCategories);
//                 setVendors(vendorsResponse);
//                 setIsLoggedIn(loginResponse.isLoggedIn);
//             } catch (error) {
//                 setError("Failed to fetch data");
//             }
//         };
//         fetchData();
//     }, []);



//     useEffect(() => {
//         const fetchProducts = async () => {
//             setLoading(true);
//             try {
//                 const response = selectedVendor
//                     ? await services.getVendorProducts(selectedVendor)
//                     : selectedCategory
//                         ? await services.getShopData(selectedCategory)
//                         : await services.getAllProducts();
//                 setShopData(response);

//                 // Initialize quantities for each product
//                 const initialQuantities = {};
//                 response.forEach((product) => {
//                     initialQuantities[product.PROD_ID] = 1;
//                 });
//                 setQuantities(initialQuantities);
//             } catch (error) {
//                 setError("Failed to fetch products");
//             } finally {
//                 setLoading(false);
//             }
//         };
//         fetchProducts();
//     }, [selectedCategory, selectedVendor]);

//     const handleCategoryChange = (event) => setSelectedCategory(event.target.value);
//     const handleVendorChange = (event) => setSelectedVendor(event.target.value);

//     const handleIncrement = (prodId) => {
//         setQuantities((prev) => ({
//             ...prev,
//             [prodId]: (prev[prodId] || 1) + 1,
//         }));
//     };

//     const handleDecrement = (prodId) => {
//         setQuantities((prev) => ({
//             ...prev,
//             [prodId]: prev[prodId] > 1 ? prev[prodId] - 1 : 1,
//         }));
//     };

//     const handleAddToCart = async (product) => {
//         const quantity = quantities[product.PROD_ID] || 1;
//         const cartData = {
//             imgurl: product.PROD_IMG_URL,
//             dispname: product.PROD_DESP,
//             prodprice: product.PROD_PRICE,
//             quantity,
//             prodid: product.PROD_ID,
//         };

//         if (isLoggedIn) {
//             try {
//                 await services.sendCARTProducts(cartData);
//                 setSuccessMessage("Product added to cart! Want to buy more?");
//             } catch (error) {
//                 setError("Error adding to cart");
//             }
//         } else {
//             let guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
//             guestCart.push({ ...product, quantity });
//             localStorage.setItem("guestCart", JSON.stringify(guestCart));
//             setSuccessMessage("Product added to guest cart successfully!");
//         }
//     };

//     if (loading) return <div>Loading...</div>;
//     if (error) return <div>Error: {error}</div>;

//     return (
//         <Box sx={{ marginTop:"8%", padding: "4%", backgroundColor: "#f8f9fa" }}>
//             <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
//                 <FormControl sx={{ minWidth: 200 }}>
//                     <InputLabel>Select Category</InputLabel>
//                     <Select label="Select Category" value={selectedCategory} onChange={handleCategoryChange}>
//                         <MenuItem value="">All Categories</MenuItem>
//                         {categories.map((category) => (
//                             <MenuItem key={category.ATTRIB_CD} value={category.ATTRIB_CD}>
//                                 {category.ATTRIB_NAME}
//                             </MenuItem>
//                         ))}
//                     </Select>
//                 </FormControl>

//                 <FormControl sx={{ minWidth: 200 }}>
//                     <InputLabel>Select Vendor</InputLabel>
//                     <Select label="Select Vendor" value={selectedVendor} onChange={handleVendorChange}>
//                         {vendors.map((vendor) => (
//                             <MenuItem key={vendor.VNDR_KY} value={vendor.VNDR_KY}>
//                                 {vendor.VNDR_F_NM} {vendor.VNDR_L_NM}
//                             </MenuItem>
//                         ))}
//                     </Select>
//                 </FormControl>
//             </Box>

//             <Grid container spacing={3}>
//                 {shopData.length > 0 ? (
//                     shopData.map((product) => (
//                         <Grid item xs={12} sm={6} md={3} key={product.PROD_ID}>
//                             <Card
//                                 sx={{
//                                     boxShadow: 4,
//                                     borderRadius: "10px",
//                                     overflow: "hidden",
//                                     transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
//                                     '&:hover': {
//                                         transform: "scale(1.05)",
//                                         boxShadow: "0px 10px 20px rgba(0,0,0,0.2)",
//                                     },
//                                 }}
//                             >

//                                 <CardMedia
//                                     sx={{
//                                         display: "flex",
//                                         justifyContent: "center",
//                                         alignItems: "center",
//                                         height: "100%",
//                                         position: "relative",
//                                     }}
//                                 >
//                                     <ProductImage className="card-img rounded-0 img-fluid" imagePath={product.PROD_IMG_URL} alt={product.PROD_DESP} />

//                                     <Box
//                                         sx={{
//                                             position: "absolute",
//                                             top: 0,
//                                             left: 0,
//                                             width: "100%",
//                                             height: "100%",
//                                             backgroundColor: "rgba(0, 0, 0, 0.2)",
//                                             opacity: 0,
//                                             transition: "opacity 0.3s ease-in-out",
//                                             display: "flex",
//                                             alignItems: "center",
//                                             justifyContent: "center",
//                                             '&:hover': {
//                                                 opacity: 1,
//                                             },
//                                         }}
//                                     >
//                                         <Link to={`/Productdetails/${product.PROD_ID}`} style={{ textDecoration: "none" }}>
//                                             <IconButton sx={{ color: "white", backgroundColor: "rgba(255,255,255,0.2)" }}>
//                                                 <Visibility />
//                                             </IconButton>
//                                         </Link>
//                                     </Box>
//                                 </CardMedia>


//                                 <CardContent sx={{ textAlign: "center" }}>
//                                     <Typography variant="h6">{product.PROD_DESP}</Typography>
//                                     <Typography variant="subtitle1" color="green">
//                                         ₹ {product.PROD_PRICE} Per KG
//                                     </Typography>
//                                     <Box mt={1} display="flex" alignItems="center">
//                                         {/* <Typography variant="h6">Quantity:</Typography> */}
//                                         <Box
//                                             sx={{
//                                                 display: "flex",
//                                                 alignItems: "center",
//                                                 ml: "30%" ,
//                                                 border: "1px solid yellow",
//                                                 borderRadius: "5px",
//                                             }}
//                                         >
//                                             <IconButton onClick={() => handleDecrement(product.PROD_ID)} size="small">
//                                                 <Remove />
//                                             </IconButton>
//                                             <Typography variant="h6" sx={{ mx: 2 }}>
//                                                 {quantities[product.PROD_ID] || 1}
//                                             </Typography>
//                                             <IconButton onClick={() => handleIncrement(product.PROD_ID)} size="small">
//                                                 <Add />
//                                             </IconButton>
//                                         </Box>
//                                     </Box>
//                                     <Button
//                                         fullWidth
//                                         variant="contained"
//                                         sx={{
//                                             width: "60%",
//                                             mt: 1,
//                                             background: "linear-gradient(90deg, #00c6ff, #0072ff)",
//                                             '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
//                                             color: "#fff",
//                                         }}
//                                         onClick={() => handleAddToCart(product)}
//                                     >
//                                         Add to Cart
//                                     </Button>

//                                 </CardContent>
//                             </Card>
//                         </Grid>
//                     ))
//                 ) : (
//                     <Typography variant="h6" sx={{ textAlign: "center", width: "100%" }}>
//                         No products found
//                     </Typography>
//                 )}
//                 <Snackbar
//                     open={Boolean(successMessage)}
//                     autoHideDuration={1000}
//                     onClose={() => setSuccessMessage("")}
//                     message={successMessage}
//                     action={
//                         <IconButton size="small" color="inherit" onClick={() => setSuccessMessage("")}>
//                             <Close />
//                         </IconButton>
//                     }
//                 />
//             </Grid>
//         </Box>
//     );
// }



import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem, Card, CardMedia, CardContent, Typography, Button, Grid, Box, Snackbar, Chip, IconButton, } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import { Add, Remove, Close } from "@mui/icons-material";
import services from "./apiServices";
import ProductImage from "./Productimage";

export default function Shop() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [shopData, setShopData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(
        query.get("category") || ""
    );
    const [categories, setCategories] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [quantities, setQuantities] = useState({}); // State to track individual product quantities
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [categoriesResponse, vendorsResponse, productsResponse, loginResponse] =
                    await Promise.all([
                        services.getCategories(),
                        services.getVendors(),
                        services.getAllProducts(),
                        services.checkLoginStatus(),
                    ]);

                // Extract available category IDs from the products
                const availableCategoryIds = new Set(productsResponse.map(product => product.PROD_TYP_ID));

                // Filter categories to include only those that have available products
                const filteredCategories = categoriesResponse.filter(category =>
                    availableCategoryIds.has(category.ATTRIB_CD)
                );

                setCategories(filteredCategories);
                setVendors(vendorsResponse);
                setIsLoggedIn(loginResponse.isLoggedIn);
            } catch (error) {
                setError("Failed to fetch data");
            }
        };
        fetchData();
    }, []);



    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = selectedVendor
                    ? await services.getVendorProducts(selectedVendor)
                    : selectedCategory
                        ? await services.getShopData(selectedCategory)
                        : await services.getAllProducts();
                setShopData(response);

                // Initialize quantities for each product
                const initialQuantities = {};
                response.forEach((product) => {
                    initialQuantities[product.PROD_ID] = 1;
                });
                setQuantities(initialQuantities);
            } catch (error) {
                setError("Failed to fetch products");
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();
    }, [selectedCategory, selectedVendor]);

    const handleCategoryChange = (event) => setSelectedCategory(event.target.value);
    const handleVendorChange = (event) => setSelectedVendor(event.target.value);

    const handleIncrement = (prodId) => {
        setQuantities((prev) => ({
            ...prev,
            [prodId]: (prev[prodId] || 1) + 1,
        }));
    };

    const handleDecrement = (prodId) => {
        setQuantities((prev) => ({
            ...prev,
            [prodId]: prev[prodId] > 1 ? prev[prodId] - 1 : 1,
        }));
    };

    const handleAddToCart = async (product) => {
        const quantity = quantities[product.PROD_ID] || 1;
        const cartData = {
            imgurl: product.PROD_IMG_URL,
            dispname: product.PROD_DESP,
            prodprice: product.PROD_PRICE,
            quantity,
            prodid: product.PROD_ID,
        };

        if (isLoggedIn) {
            try {
                await services.sendCARTProducts(cartData);
                setSuccessMessage("Product added to cart! Want to buy more?");
            } catch (error) {
                setError("Error adding to cart");
            }
        } else {
            let guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
            guestCart.push({ ...product, quantity });
            localStorage.setItem("guestCart", JSON.stringify(guestCart));
            setSuccessMessage("Product added to guest cart successfully!");
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Box className="container-box">
            <Box className="filter-box">
                <FormControl className="filter-control">
                    <InputLabel>Select Category</InputLabel>
                    <Select label="Select Category" value={selectedCategory} onChange={handleCategoryChange}>
                        <MenuItem value="">All Categories</MenuItem>
                        {categories.map((category) => (
                            <MenuItem key={category.ATTRIB_CD} value={category.ATTRIB_CD}>
                                {category.ATTRIB_NAME}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className="filter-control">
                    <InputLabel>Select Vendor</InputLabel>
                    <Select label="Select Vendor" value={selectedVendor} onChange={handleVendorChange}>
                        <MenuItem value="">All Vendors</MenuItem>
                        {vendors.map((vendor) => (
                            <MenuItem key={vendor.VNDR_KY} value={vendor.VNDR_KY}>
                                {vendor.VNDR_F_NM} {vendor.VNDR_L_NM}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Grid container spacing={3}>
                {shopData.length > 0 ? (
                    shopData.map((product) => (
                        <Grid item xs={6} sm={4} md={3} key={product.PROD_ID}>
                            <Card className="product-card">
                                <CardMedia className="product-media">
                                    <ProductImage className="card-img" imagePath={product.PROD_IMG_URL} alt={product.PROD_DESP} />
                                    <Box className="product-overlay">
                                        <Link to={`/Productdetails/${product.PROD_ID}`} className="overlay-link">
                                            <IconButton className="overlay-icon">
                                                <Visibility />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                </CardMedia>
                                <CardContent className="card-content">
                                    <Box className="card-description">
                                        <Typography variant="h6">{product.PROD_DESP}</Typography>
                                        <Typography variant="subtitle1" color="green">
                                            ₹ {product.PROD_PRICE} Per KG
                                        </Typography>
                                    </Box>
                                    <Box className="quantity-box">
                                        <IconButton onClick={() => handleDecrement(product.PROD_ID)} size="small">
                                            <Remove />
                                        </IconButton>
                                        <Typography variant="h6" className="quantity-text">
                                            {quantities[product.PROD_ID] || 1}
                                        </Typography>
                                        <IconButton onClick={() => handleIncrement(product.PROD_ID)} size="small">
                                            <Add />
                                        </IconButton>
                                    </Box>
                                    <Button className="add-to-cart" fullWidth variant="contained" onClick={() => handleAddToCart(product)}>
                                        Add to Cart
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Typography variant="h6" className="no-products">
                        No products found
                    </Typography>
                )}
            </Grid>

            <Snackbar
                open={Boolean(successMessage)}
                autoHideDuration={1000}
                onClose={() => setSuccessMessage("")}
                message={successMessage}
                action={
                    <IconButton size="small" color="inherit" onClick={() => setSuccessMessage("")}> <Close /> </IconButton>
                }
            />
        </Box>

    );
}
// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import services from './apiServices';
// import ProductImage from './Productimage';

// export default function Productdetails() {
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [product, setProduct] = useState(null);
//     const [quantity, setQuantity] = useState(1);
//     const [cartprods, setCartprods] = useState({ prodid: 0, quantityp: 0 });
//     const [isLoggedIn, setIsLoggedIn] = useState(false); // Assuming you have a way to check if user is logged in
//     const [successMessage, setSuccessMessage] = useState('');


//     useEffect(() => {
//         const fetchProduct = async () => {
//             try {
//                 const data = await services.getSINGLEProducts(id);
//                 setProduct(data);
//                 console.log("Fetched product data:", data);
//             } catch (error) {
//                 console.error("There was an error fetching the product!", error);
//             }
//         };

//         fetchProduct();
//     }, [id]);

//     useEffect(() => {
//         setCartprods({ prodid: id, quantityp: quantity });
//     }, [id, quantity]);

//     useEffect(() => {
//         // Check if the user is logged in
//         const checkLoginStatus = async () => {
//             try {
//                 const response = await services.checkLoginStatus(); // Assuming you have an endpoint to check login status
//                 setIsLoggedIn(response.isLoggedIn);
//             } catch (error) {
//                 console.error("Error checking login status", error);
//             }
//         };

//         checkLoginStatus();
//     }, []);

//     const handleIncrement = () => {
//         setQuantity(prevQuantity => prevQuantity + 1);
//     };

//     const handleDecrement = () => {
//         setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : prevQuantity));
//     };

//     // useEffect({},[prodcartdata])
//     const [prodcartdata,setprodcartdata]=useState({
//         imgurl:"",
//         quantity:0,
//         prodid:0,
//         dispname:"",
//         prodprice:"",
//     })
//     const handleAddToCart = async (e) => {
//         e.preventDefault();
//         const cartData = {
//             imgurl: product.PROD_IMG_URL,
//             dispname: product.PROD_DESP,
//             prodprice: product.PROD_PRICE,
//             quantity: cartprods.quantityp,
//             prodid: cartprods.prodid,
//           };
//         if (isLoggedIn) {
//             try {
//                 setprodcartdata(cartData)
//                 const data = await services.sendCARTProducts(cartData); 
//                 console.log(data);
//                 setSuccessMessage('Product added to cart! Want to Buy More Products Go To SHOP!'); // Set success message
//                 // Clear message after a few seconds
//                 setTimeout(() => {
//                     setSuccessMessage('');
//                 }, 7000);
//             } catch (error) {
//                 console.error("There was an error adding the product to the cart!", error);
//             }
//         } else {
//             // Store cart data in localStorage for guest users
//             let guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
//             guestCart.push({ ...product, quantity });
//             localStorage.setItem('guestCart', JSON.stringify(guestCart));
//             console.log("Product added to guest cart");

//             // Set success message for guest cart
//             setSuccessMessage('Product added to guest cart successfully!');

//             // Clear success message after 3 seconds
//             setTimeout(() => {
//                 setSuccessMessage('');
//             }, 3000);
//         }
//     };
//     console.log(prodcartdata)

//     if (!product) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <div>
//             <section className="bg-light">
//                 <div className="container pb-5">
//                     <div className="row">
//                         <div className="col-lg-5 mt-5">
//                             <div className="card mb-3">
//                                 <ProductImage className="card-img img-fluid" imagePath={product.PROD_IMG_URL} alt="Product" id="product-detail"/>
//                             </div>
//                             <div className="row">
//                                 <div className="col-1 align-self-center">
//                                     <a href="#multi-item-example" role="button" data-bs-slide="prev">
//                                         <i className="text-dark fas fa-chevron-left"></i>
//                                         <span className="sr-only">Previous</span>
//                                     </a>
//                                 </div>
//                                 <div id="multi-item-example" className="col-10 carousel slide carousel-multi-item" data-bs-ride="carousel">
//                                     <div className="carousel-inner product-links-wap" role="listbox">
//                                         {product.images && product.images.length > 0 && product.images.map((image, index) => (
//                                             <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
//                                                 <div className="row">
//                                                     <div className="col-4">
//                                                         <a href="#">
//                                                             <ProductImage className="card-img img-fluid" imagePath={`${image.IMG_PATH}/${image.IMG_FL_NM}`} alt={`Product Image ${index + 1}`}/>
//                                                         </a>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </div>
//                                 </div>
//                                 <div className="col-1 align-self-center">
//                                     <a href="#multi-item-example" role="button" data-bs-slide="next">
//                                         <i className="text-dark fas fa-chevron-right"></i>
//                                         <span className="sr-only">Next</span>
//                                     </a>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-lg-7 mt-5">
//                             <div className="card">
//                                 <div className="card-body">
//                                     <h1 className="h2">{product.PROD_DESP}</h1>
//                                     <p className="h3 py-2">₹ {product.PROD_PRICE}</p>
//                                     <h5>Description:</h5>
//                                     <h4>{product.PROD_DESP} Per KG</h4>                                  
//                                     <form onSubmit={handleAddToCart}>
//                                         <input type="hidden" name="product-title" value={product.PROD_DESP}/>
//                                         <div className="row">
//                                             <div className="col-auto">
//                                                 <ul className="list-inline pb-3">
//                                                     <li className="list-inline-item">Weight :
//                                                         <input type="hidden" name="product-size" id="product-size" value="S"/>
//                                                     </li>
//                                                     <li className="list-inline-item"><span className="btn btn-success btn-size">Kg</span></li>
//                                                 </ul>
//                                             </div>
//                                             <div className="col-auto">
//                                                 <ul className="list-inline pb-3">
//                                                     <li className="list-inline-item text-right">
//                                                         Quantity
//                                                         <input type="hidden" name="product-quanity" id="product-quanity" value={quantity}/>
//                                                     </li>
//                                                     <li className="list-inline-item"><span className="btn btn-success" onClick={handleDecrement}>-</span></li>
//                                                     <li className="list-inline-item"><span className="badge bg-secondary">{quantity}</span></li>
//                                                     <li className="list-inline-item"><span className="btn btn-success" onClick={handleIncrement}>+</span></li>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                         <div className="row pb-3">
//                                             <div className="col d-grid">
//                                                 <button className="btn btn-success btn-lg" onClick={handleAddToCart}>Add to Cart</button><br></br>
//                                             </div>
//                                         </div>
//                                     </form>
//                                     {/* Display success message */}
//                                     {successMessage && <div className="alert alert-success">{successMessage}</div>}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     );
// }



import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import services from "./apiServices";
import ProductImage from "./Productimage";
import { Card, CardContent, Typography, Button, Grid, Box, Snackbar, IconButton, Rating } from "@mui/material";
import { Add, Remove, Close } from "@mui/icons-material";

export default function Productdetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [cartprods, setCartprods] = useState({ prodid: 0, quantityp: 0 });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const data = await services.getSINGLEProducts(id);
                setProduct(data);
            } catch (error) {
                console.error("Error fetching product!", error);
            }
        };
        fetchProduct();
    }, [id]);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await services.getProductReviews(id);
                if (Array.isArray(response)) {
                    setReviews(response); // Only set if it's an array
                } else {
                    setReviews([]); // Fallback to an empty array
                }
            } catch (error) {
                console.error("Error fetching reviews!", error);
                setReviews([]); // Handle errors gracefully
            }
        };
        fetchReviews();
    }, [id]);

    useEffect(() => {
        setCartprods({ prodid: id, quantityp: quantity });
    }, [id, quantity]);

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const response = await services.checkLoginStatus();
                setIsLoggedIn(response.isLoggedIn);
            } catch (error) {
                console.error("Error checking login status", error);
            }
        };
        checkLoginStatus();
    }, []);

    const handleIncrement = () => setQuantity((prev) => prev + 1);
    const handleDecrement = () => setQuantity((prev) => (prev > 1 ? prev - 1 : prev));

    const handleAddToCart = async (e) => {
        e.preventDefault();
        const cartData = {
            imgurl: product.PROD_IMG_URL,
            dispname: product.PROD_DESP,
            prodprice: product.PROD_PRICE,
            quantity: cartprods.quantityp,
            prodid: cartprods.prodid,
        };

        if (isLoggedIn) {
            try {
                await services.sendCARTProducts(cartData);
                setSuccessMessage("Product added to cart! Want to buy more? Go to SHOP!");
            } catch (error) {
                console.error("Error adding to cart!", error);
            }
        } else {
            let guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
            guestCart.push({ ...product, quantity });
            localStorage.setItem("guestCart", JSON.stringify(guestCart));
            setSuccessMessage("Product added to guest cart successfully!");
        }
    };

    const handleWriteReview = () => {
        if (isLoggedIn) {
            // Navigate to the user's purchase history page if logged in
            navigate("/Userpuchasehistory");
        } else {
            // Navigate to the login page if not logged in
            navigate("/Loginuser");
        }
    };

    if (!product) return <div>Loading...</div>;

    return (
        <Box className="product-container">
            <Grid container spacing={4} justifyContent="center">
                {/* Product Image Section */}
                <Grid item xs={12} md={5}>
                    <Card className="product-image-card">
                        <ProductImage imagePath={product.PROD_IMG_URL} alt="Product" />
                    </Card>
                </Grid>

                {/* Product Details Section */}
                <Grid item xs={12} md={6}>
                    <Card className="product-details-card">
                        <CardContent>
                            <Typography variant="h4" className="product-title">
                                {product.PROD_DESP}
                            </Typography>
                            <Typography variant="h5" className="product-price">
                                ₹ {product.PROD_PRICE}
                            </Typography>
                            <Typography variant="subtitle1" className="product-subtitle">
                                {product.PROD_DESP} Per KG
                            </Typography>

                            {/* Quantity Selector */}
                            <Box className="quantity-container">
                                <Typography variant="h6">Quantity:</Typography>
                                <Box className="quantity-boxproddetails">
                                    <IconButton onClick={handleDecrement} size="small">
                                        <Remove />
                                    </IconButton>
                                    <Typography variant="h6" className="quantity-text">
                                        {quantity}
                                    </Typography>
                                    <IconButton onClick={handleIncrement} size="small">
                                        <Add />
                                    </IconButton>
                                </Box>
                            </Box>

                            {/* Add to Cart Button */}
                            <Button
                                fullWidth
                                variant="contained"
                                color="success"
                                className="add-to-cart-btn"
                                onClick={handleAddToCart}
                            >
                                Add to Cart
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Customer Reviews */}
            <Grid container fullWidth justifyContent="center" mt={4}>
                <Grid item xs={12} md={11}>
                    <Card className="customer-reviews-card">
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Customer Reviews</Typography>
                            {Array.isArray(reviews) && reviews.length > 0 ? (
                                reviews.map((review, index) => (
                                    <Box key={index} className="review-box">
                                        <Typography variant="subtitle1" className="review-author">
                                            {review.USR_F_NM}
                                        </Typography>
                                        <Box className="review-rating">
                                            <Rating value={review.ORDR_PROD_RATING} readOnly precision={0.5} />
                                            <Typography variant="body2" className="rating-text">
                                                {review.ORDR_PROD_RATING.toFixed(1)}
                                            </Typography>
                                        </Box>
                                        <Typography variant="body2" className="review-text">
                                            {review.ORDR_PROD_RIVEW}
                                        </Typography>
                                    </Box>
                                ))
                            ) : (
                                <Typography variant="body2" className="no-reviews">No reviews yet.</Typography>
                            )}
                        </CardContent>
                        <Typography variant="h6" className="review-heading">Review this product</Typography>
                        <Typography variant="subtitle2" className="review-subtext">
                            Share your thoughts with other customers
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            className="write-review-btn"
                            onClick={handleWriteReview}
                        >
                            Write a product review
                        </Button>
                    </Card>
                </Grid>
            </Grid>

            {/* Success Snackbar */}
            <Snackbar
                open={Boolean(successMessage)}
                autoHideDuration={4000}
                onClose={() => setSuccessMessage("")}
                message={successMessage}
                action={
                    <IconButton size="small" color="inherit" onClick={() => setSuccessMessage("")}>
                        <Close />
                    </IconButton>
                }
            />
        </Box>
    );
}

// import React, { useState } from 'react';
// import { Link } from "react-router-dom";
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import Alert from '@mui/material/Alert';
// import IconButton from '@mui/material/IconButton';
// import InputAdornment from '@mui/material/InputAdornment';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import services from './apiServices';

// export default function Loginuser() {
//     const [logincreds, setLoginCreds] = useState({
//         username: '',
//         password: ''
//     });
//     const [error, setError] = useState({
//         username: '',
//         password: ''
//     });
//     const [showPassword, setShowPassword] = useState(false);
//     const [successMessage, setSuccessMessage] = useState(''); // New state for success message

//     const handleChange = (e) => {
//         setLoginCreds({
//             ...logincreds,
//             [e.target.name]: e.target.value
//         }); 
//         setError({
//             ...error,
//             [e.target.name]: ''
//         });
//         setSuccessMessage('');
//     };

//     const handleTogglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Validate form inputs
//         if (logincreds.username === '' && logincreds.password === '') {
//             setError({
//                 ...error,
//                 username: 'username is required.',
//                 password: 'Password is required',
//                 message: ''
//             });
//             return;
//         }

//         if (logincreds.username === '') {
//             setError({
//                 ...error,
//                 username: 'username is required.',
//                 message: ''
//             });
//             return;
//         }

//         if (logincreds.password === '') {
//             setError({
//                 ...error,
//                 password: 'Password is required',
//                 message: ''
//             });
//             return;
//         }

//         try {
//             const res = await services.sendLoginData(logincreds);
//             console.log(res); // Handle the response as needed
//             // Clear errors and handle successful login
//             setError({
//                 username: '',
//                 password: '',
//                 message: ''
//             });
//             // Optionally redirect or update the UI based on successful login
//             setSuccessMessage('Login successful!'); // Set success message
//             window.location.replace('/shop')
//         } catch (error) {
//             //Set a generic error message for failed login
//             setError({
//                 username: '',
//                 password: '',
//                 username: 'Login failed. Please check your credentials and try again.'
//             });
//             setSuccessMessage('');
//         }
//     };

//     return (
//         <div>
//             <section className="cd-hero">
//                 <ul className="cd-hero-slider">
//                     <li className="selected">
//                         <div className="heading">
//                             <h1>Login</h1>
//                             <span>Welcome</span>
//                             <p>&nbsp;</p>
//                         </div>
//                         <div className="cd-full-width first-slide cd-hero-form">
//                             <div className="container">
//                                 <div className="row row-custom">
//                                     <div className="col-md-12 row-custom">
//                                         <div className="content login-content login-custom">
//                                             <h4 className='custom-label-login'>Login</h4>
//                                             <form name="frm_login" id="frm_login" onSubmit={handleSubmit} action="" className="form-custom">
//                                                 <div className="row size">
//                                                     <div className="col-md-4 custom-label">
//                                                         {/* <label>username :</label> */}
//                                                     </div>
//                                                     <div className="col-md-8">
//                                                         <TextField
//                                                             style={{ width: '50%' }}
//                                                             id="outlined-basic"
//                                                             className='size'
//                                                             label="username"
//                                                             variant="outlined"
//                                                             size='small'
//                                                             color='warning'
//                                                             name="username"
//                                                             value={logincreds.username}
//                                                             onChange={handleChange}
//                                                         />
//                                                         {error.username && <Alert severity="error" style={{ width: '50%' }}>{error.username}</Alert>}
//                                                     </div>
//                                                 </div>
//                                                 <div className="row size">
//                                                     <div className="col-md-4 custom-label">
//                                                         {/* <label>Password :</label> */}
//                                                     </div>
//                                                     <div className="col-md-8">
//                                                         <TextField
//                                                             style={{ width: '50%' }}
//                                                             id="outlined-basic"
//                                                             name="password"
//                                                             type={showPassword ? 'text' : 'password'}
//                                                             label="Password"
//                                                             variant="outlined"
//                                                             size='small'
//                                                             color='warning'
//                                                             value={logincreds.password}
//                                                             onChange={handleChange}
//                                                             InputProps={{
//                                                                 endAdornment: (
//                                                                     <InputAdornment position="end">
//                                                                         <IconButton
//                                                                             aria-label="toggle password visibility"
//                                                                             onClick={handleTogglePasswordVisibility}
//                                                                             edge="end"
//                                                                         >
//                                                                             {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
//                                                                         </IconButton>
//                                                                     </InputAdornment>
//                                                                 ),
//                                                             }}
//                                                         />
//                                                         {error.password && <Alert severity="error" style={{ width: '100%' }}>{error.password}</Alert>}
//                                                     </div>
//                                                 </div>
//                                                 <div className="col-md-12">
//                                                     <div className="primary-button custom-button">
//                                                         <Button sx={{
//                                             mt: 1,
//                                             background: "linear-gradient(90deg, #00c6ff, #0072ff)",
//                                             '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
//                                             color: "#fff",
//                                         }} type="submit" id="form-submit" className="btn" >&nbsp;&nbsp;<b>Login</b>&nbsp;&nbsp;</Button>
//                                                         &nbsp;&nbsp;&nbsp;&nbsp;<br></br><br></br>
//                                                         <Link to="/Registrationuser"  style={{textDecoration:"none"}} >&nbsp;&nbsp;<b>New to HSB? Create an account</b>&nbsp;&nbsp;</Link>
//                                                     </div>
//                                                 </div>
//                                             </form>
//                                             {/* Display overall login error */}
//                                             {error.username === '' && error.password === '' && error.message && <Alert severity="error">{error.message}</Alert>}
//                                             {/* Display success message */}
//                                             {successMessage && <Alert severity="success">{successMessage}</Alert>}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </li>
//                 </ul>
//             </section>
//         </div>
//     );
// }


import React, { useState } from 'react';
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import services from './apiServices';

export default function Loginuser() {
    const [logincreds, setLoginCreds] = useState({
        username: '',
        password: ''
    });

    const [error, setError] = useState({
        username: '',
        password: ''
    });

    const [showPassword, setShowPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        setLoginCreds({
            ...logincreds,
            [e.target.name]: e.target.value
        });
        setError({
            ...error,
            [e.target.name]: ''
        });
        setSuccessMessage('');
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (logincreds.username === '' || logincreds.password === '') {
            setError({
                username: logincreds.username === '' ? 'Username is required.' : '',
                password: logincreds.password === '' ? 'Password is required.' : ''
            });
            return;
        }

        try {
            const res = await services.sendLoginData(logincreds);
            console.log(res);
            setError({ username: '', password: '', message: '' });
            setSuccessMessage('Login successful!');
            window.location.replace('/shop');
        } catch (error) {
            setError({ username: 'Login failed. Please check your credentials and try again.', password: '' });
            setSuccessMessage('');
        }
    };

    return (
        <section className="login-container">
            <div className="login-header">
              <h1>Welcome</h1>
            </div>
            <div className="login-form-container">
                <div className="login-content">
                    <h4 className="login-title">Login</h4>
                    <form name="frm_login" id="frm_login" onSubmit={handleSubmit} className="login-form">
                        <div className="form-group">
                            <TextField
                                id="username"
                                className="input-field"
                                label="Username"
                                variant="outlined"
                                size="small"
                                color="warning"
                                name="username"
                                value={logincreds.username}
                                onChange={handleChange}
                                fullWidth
                            />
                            {error.username && <Alert severity="error" className="alert-field">{error.username}</Alert>}
                        </div>

                        <div className="form-group">
                            <TextField
                                id="password"
                                className="input-field"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                variant="outlined"
                                size="small"
                                color="warning"
                                value={logincreds.password}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                            {error.password && <Alert severity="error" className="alert-field">{error.password}</Alert>}
                        </div>

                        <div className="form-group">
                            <Button type="submit" id="form-submit" className="login-btn" variant="contained">
                                Login
                            </Button>
                        </div>

                        <div className="form-group">
                            <Link to="/Registrationuser" className="register-link">
                                New to HSB? Create an account
                            </Link>
                        </div>

                        {error.username === "" && error.password === "" && error.message && <Alert severity="error">{error.message}</Alert>}
                        {successMessage && <Alert severity="success">{successMessage}</Alert>}
                    </form>
                </div>
            </div>
        </section>
    );
}

import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, TextField, Grid, Typography, Card, CardContent, Box, Button, Dialog, DialogTitle, DialogContent, Rating } from '@mui/material';
import services from './apiServices'; // Ensure this imports your services module
import SmallProductImage from './smallproductimg';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const UserPurchaseHistory = () => {
    const [orders, setOrders] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [message, setMessage] = useState('');
    const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
    const [reviewText, setReviewText] = useState('');
    const [reviewRating, setReviewRating] = useState(5);
    const [selectedProduct, setSelectedProduct] = useState(null);


    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const lve_tp = await services.purchasehistory();
                if (lve_tp && Array.isArray(lve_tp.orderRows)) {
                    const groupedOrders = lve_tp.orderRows.reduce((acc, order) => {
                        const orderId = order.GNRTD_ORD_NO;
                        if (!acc[orderId]) acc[orderId] = { items: [], totalAmount: 0, totalQuantity: 0 };

                        acc[orderId].items.push(order);
                        acc[orderId].totalAmount = parseFloat(order.ORDR_TOT_GROS_AMT);
                        acc[orderId].totalQuantity = parseInt(order.ORDR_DLVRY_ITM_CNT);

                        return acc;
                    }, {});
                    setOrders(groupedOrders);
                } else {
                    console.error("Unexpected data format:", lve_tp);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Failed to fetch purchase history.");
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const handleOpenReviewDialog = (product) => {
        setSelectedProduct(product);
        setReviewDialogOpen(true);
    };

    const handleCloseReviewDialog = () => {
        setReviewDialogOpen(false);
        setReviewText('');
        setReviewRating(5);
    };

    const handleSubmitReview = async () => {
        if (!selectedProduct || !selectedProduct.PROD_ID) {
            return;
        }

        if (!reviewText || reviewRating == null) {
            return;
        }

        const reviewData = {
            prodId: selectedProduct.PROD_ID,  // Ensure this is properly set
            review: reviewText,
            rating: reviewRating,
        };

        console.log("Submitting review data:", reviewData);

        try {
            const response = await services.submitReview(reviewData);
            handleCloseReviewDialog();
        } catch (error) {
        }
    };


    const handleViewDetails = (orderId) => {
        setSelectedOrder(orders[orderId]);
    };

    const handleCloseDialog = () => {
        setSelectedOrder(null);
    };

    const handleDownloadInvoice = async (orderId) => {
        console.log("Starting invoice generation...");

        // Retrieve order details
        const orderItems = orders[orderId]?.items;

        if (!orderItems || orderItems.length === 0) {
            console.error("No items found for this order.");
            return;
        }

        // Log order details to ensure data is present
        console.log("Order Items:", orderItems);

        // Prepare customer details from the first item
        const firstItem = orderItems[0];
        const customerDetails = {
            name: firstItem.USR_DISPLAY_NAME || "N/A",
            email: firstItem.USR_EMAIL || "N/A",
            address: [
                firstItem.ADD_LN1 || "",
                firstItem.ADD_LN2 || "",
                firstItem.ADD_LN3 || "",
                firstItem.ADD_CT || "",
                firstItem.ADD_PIN_ZIP || ""
            ].filter(part => part).join(", ") // Join only non-empty address parts
        };
        console.log("Customer Details:", customerDetails);

        // Map product details for all items in the order
        const productDetails = orderItems.map(product => ({
            name: product.PROD_DESP || "Unknown Product",
            price: parseFloat(product.ORDR_ORGNL_RATE) || 0,
            quantity: product.ORDR_QTY || 1
        }));
        console.log("Product Details:", productDetails);

        // Proceed with backend fetch and PDF generation
        try {
            console.log("Sending invoice data to backend...");

            const response = await fetch('/api/Purchasehistory/generate-invoiceforhistory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    GNRTD_ORD_NO: orderId,
                    customerDetails,
                    productDetails
                }),
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
                console.log("Invoice generated successfully.");
            } else {
                console.error('Error generating PDF:', response.statusText);
            }
        } catch (error) {
            console.error('Error generating invoice:', error);
        }
    };


    const renderOrderHistory = () => (
        <Grid container spacing={4}>
            {Object.entries(orders).map(([orderId, orderData]) => (
                <Grid item key={orderId} xs={12} md={6}>
                    <Card sx={{ maxWidth: 345, height: '321px' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Order ID: {orderId}
                            </Typography>
                            {orderData.items.slice(0, 2).map((order, index) => (
                                <Box key={index}>
                                    <Typography variant="body2" color="textSecondary">Product: {order.PROD_DESP}</Typography>
                                </Box>
                            ))}
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                Total Items: {orderData.totalQuantity}
                            </Typography>
                            <Button sx={{
                                mt: 1,
                                background: "linear-gradient(90deg, #ffd400, #0072ff)",
                                '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
                                color: "#fff",
                            }} variant="contained" size="small" onClick={() => handleViewDetails(orderId)}>
                                View Details
                            </Button>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                Total Order Amount: ₹{orderData.totalAmount.toFixed(2)}
                            </Typography>
                            <Button sx={{
                                mt: 1,
                                background: "linear-gradient(90deg, #00c6ff, #0072ff)",
                                '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
                                color: "#fff",
                            }} variant="contained" color="primary" onClick={() => handleDownloadInvoice(orderId)}>
                                Download Invoice
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    if (loading) {
        return (
            <Container sx={{ textAlign: 'center', mt: 5 }}>
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>Loading your purchase history...</Typography>
            </Container>
        );
    }

    if (error) {
        return (
            <Container sx={{ textAlign: 'center', mt: 5 }}>
                <Typography variant="h6" color="error">{error}</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    Please check your internet connection or try again later.
                </Typography>
            </Container>
        );
    }

    return (
        // <Container sx={{ mt: 5 }}>
        //     <Typography variant="h4" gutterBottom>Your Purchase History</Typography>
        //     {Object.keys(orders).length === 0 ? (
        //         <Typography variant="h6" color="textSecondary" sx={{ mt: 3 }}>
        //             You have not placed any orders yet.
        //         </Typography>
        //     ) : (
        //         renderOrderHistory()
        //     )}
        //     <Dialog open={Boolean(selectedOrder)} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        //         <DialogTitle>
        //             <Box display="flex" justifyContent="space-between" alignItems="center">
        //                 <Typography variant="h6">Order Details</Typography>
        //                 <Button onClick={handleCloseDialog} color="primary">
        //                     Close
        //                 </Button>
        //             </Box>
        //         </DialogTitle>
        //         <DialogContent>
        //             {selectedOrder && (
        //                 <Box sx={{ mt: 2, borderTop: '1px solid #ccc', pt: 2 }}>
        //                     <Box display="flex" justifyContent="space-between">
        //                         <Typography variant="body2">Order ID: {selectedOrder.items[0].GNRTD_ORD_NO}</Typography>
        //                         <Typography variant="body2">Order Date: {new Date(selectedOrder.items[0].ORDR_DATE).toLocaleDateString()}</Typography>
        //                     </Box>
        //                     <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
        //                         <Typography variant="body2">Order Invoice Number: {selectedOrder.items[0].ORDR_S2H_INV_NO}</Typography>
        //                         <Typography variant="body2">Invoice Date: {new Date(selectedOrder.items[0].USR_REF_INV_DT).toLocaleDateString()}</Typography>
        //                     </Box>
        //                     <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
        //                         <Typography variant="body2">Status: {selectedOrder.items[0].ORDR_STTS}</Typography>
        //                     </Box>
        //                 </Box>
        //             )}
        //             {selectedOrder && selectedOrder.items.map((item, index) => (
        //                 <Card key={index} sx={{ mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        //                     <SmallProductImage imagePath={item.PROD_IMG_URL} alt={item.PROD_DESP} />
        //                     <CardContent sx={{ flex: 1 }}>
        //                         <Typography variant="body2">Product: {item.PROD_DESP}</Typography>
        //                         <Typography variant="body2">Amount: ₹{item.ORDR_ORGNL_RATE}</Typography>
        //                         <Typography variant="body2">Quantity: {item.ORDR_QTY}</Typography>
        //                         <Typography variant="body2">UOM: {item.PROD_UOM}</Typography>
        //                         <Button variant="contained" sx={{
        //                             width: "44%",
        //                             background: "linear-gradient(90deg, #00c6ff, #0072ff)",
        //                             '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
        //                             color: "#fff",
        //                             display: 'flex',
        //                             justifyContent: 'flex-end', // Aligns the button to the right
        //                             marginLeft: 'auto', // Pushes the button to the right
        //                         }} color="primary" onClick={() => {
        //                             console.log("Selected Product for Review:", item);
        //                             handleOpenReviewDialog(item);
        //                         }}>
        //                             Write a Review
        //                         </Button>
        //                     </CardContent>
        //                 </Card>
        //             ))}
        //             <Dialog open={reviewDialogOpen} onClose={handleCloseReviewDialog}>
        //                 <DialogTitle>Write a Review</DialogTitle>
        //                 <DialogContent>
        //                     <Typography variant="body2">Product: {selectedProduct?.PROD_DESP}</Typography>
        //                     <TextField
        //                         fullWidth
        //                         label="Your Review"
        //                         multiline
        //                         rows={3}
        //                         value={reviewText}
        //                         onChange={(e) => setReviewText(e.target.value)}
        //                         sx={{ mt: 2 }}
        //                     />
        //                     <Typography sx={{ mt: 2 }}>How Was The Item:</Typography>
        //                     <Rating
        //                         name="product-rating"
        //                         value={reviewRating}
        //                         onChange={(e, newValue) => setReviewRating(newValue)}
        //                     /><br></br>
        //                     <Button onClick={handleSubmitReview} variant="contained" sx={{
        //                         width: "67%",
        //                         background: "linear-gradient(90deg, #00c6ff, #0072ff)",
        //                         '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
        //                         color: "#fff",
        //                         display: 'flex',
        //                         justifyContent: 'flex-end', // Aligns the button to the right
        //                         marginLeft: 'auto', // Pushes the button to the right
        //                     }}>
        //                         Submit Review
        //                     </Button>
        //                 </DialogContent>
        //             </Dialog>
        //             {selectedOrder && (
        //                 <Box sx={{ mt: 2, borderTop: '1px solid #ccc', pt: 2 }}>
        //                     <Box display="flex" justifyContent="space-between">
        //                         <Typography variant="body2">Total Items: {selectedOrder.totalQuantity}</Typography>
        //                         <Typography variant="body2" >
        //                             Total Order Amount: ₹{selectedOrder.totalAmount.toFixed(2)}
        //                         </Typography>
        //                     </Box>
        //                     <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
        //                         <Typography variant="body2">Order Payment Mode: {selectedOrder.items[0].ORDR_PYMT_MOD}</Typography>
        //                         <Typography variant="body2">Payment Date: {new Date(selectedOrder.items[0].ORDR_PYMT_DT).toLocaleDateString()}</Typography>
        //                     </Box>
        //                 </Box>
        //             )}
        //         </DialogContent>
        //     </Dialog>
        // </Container>
        <Container className="purchasehistory">
            <Typography variant="h4" gutterBottom>Your Purchase History</Typography>
            {Object.keys(orders).length === 0 ? (
                <Typography variant="h6" color="textSecondary" className="order-message">
                    You have not placed any orders yet.
                </Typography>
            ) : (
                renderOrderHistory()
            )}
            <Dialog open={Boolean(selectedOrder)} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Order Details</Typography>
                        <Button onClick={handleCloseDialog} color="primary">Close</Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {selectedOrder && (
                        <Box className="order-details">
                            <Box className="order-info">
                                <Typography variant="body2">Order ID: {selectedOrder.items[0].GNRTD_ORD_NO}</Typography>
                                <Typography variant="body2">Order Date: {new Date(selectedOrder.items[0].ORDR_DATE).toLocaleDateString()}</Typography>
                            </Box>
                            <Box className="order-info">
                                <Typography variant="body2">Order Invoice Number: {selectedOrder.items[0].ORDR_S2H_INV_NO}</Typography>
                                <Typography variant="body2">Invoice Date: {new Date(selectedOrder.items[0].USR_REF_INV_DT).toLocaleDateString()}</Typography>
                            </Box>
                            <Box className="order-info">
                                <Typography variant="body2">Status: {selectedOrder.items[0].ORDR_STTS}</Typography>
                            </Box>
                        </Box>
                    )}
                    {selectedOrder && selectedOrder.items.map((item, index) => (
                        <Card key={index} className="card-style">
                            <SmallProductImage imagePath={item.PROD_IMG_URL} alt={item.PROD_DESP} />
                            <CardContent className="card-content">
                                <Typography variant="body2">Product: {item.PROD_DESP}</Typography>
                                <Typography variant="body2">Amount: ₹{item.ORDR_ORGNL_RATE}</Typography>
                                <Typography variant="body2">Quantity: {item.ORDR_QTY}</Typography>
                                <Typography variant="body2">UOM: {item.PROD_UOM}</Typography>
                                <Button
                                    variant="contained"
                                    className="review-button"
                                    color="primary"
                                    onClick={() => handleOpenReviewDialog(item)}
                                >
                                    Write a Review
                                </Button>
                            </CardContent>
                        </Card>
                    ))}
                    <Dialog open={reviewDialogOpen} onClose={handleCloseReviewDialog}>
                        <DialogTitle>Write a Review</DialogTitle>
                        <DialogContent>
                            <Typography variant="body2">Product: {selectedProduct?.PROD_DESP}</Typography>
                            <TextField
                                fullWidth
                                label="Your Review"
                                multiline
                                rows={3}
                                value={reviewText}
                                onChange={(e) => setReviewText(e.target.value)}
                                className="order-message"
                            />
                            <Typography className="order-message">How Was The Item:</Typography>
                            <Rating
                                name="product-rating"
                                value={reviewRating}
                                onChange={(e, newValue) => setReviewRating(newValue)}
                            /><br />
                            <Button onClick={handleSubmitReview} variant="contained" className="submit-review-button">
                                Submit Review
                            </Button>
                        </DialogContent>
                    </Dialog>
                    {selectedOrder && (
                        <Box className="order-details">
                            <Box className="order-info">
                                <Typography variant="body2">Total Items: {selectedOrder.totalQuantity}</Typography>
                                <Typography variant="body2">
                                    Total Order Amount: ₹{selectedOrder.totalAmount.toFixed(2)}
                                </Typography>
                            </Box>
                            <Box className="order-info">
                                <Typography variant="body2">Order Payment Mode: {selectedOrder.items[0].ORDR_PYMT_MOD}</Typography>
                                <Typography variant="body2">Payment Date: {new Date(selectedOrder.items[0].ORDR_PYMT_DT).toLocaleDateString()}</Typography>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default UserPurchaseHistory;

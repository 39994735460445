// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import services from './apiServices';
// import bnrimgone from '../img/RDBS imgs/banner_img_01_resized.png';
// import bnrimgthree from '../img/banner_img_03.jpg';
// import ProductImage from './Productimage';
// import { Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
// import { ChevronLeft, ChevronRight } from "@mui/icons-material";



// export default function Home() {
//   const [categories, setCategories] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const prodTypeId = 'Vegetables'; // Example category ID as a string
//         const response = await services.fetchProductsByCategory(prodTypeId);

//         // Log the response to debug
//         console.log('Response:', response);

//         // Handle the case when no products are found
//         if (Array.isArray(response)) {
//           setCategories(response);
//         } else {
//           setError('Unexpected response format');
//         }
//       } catch (error) {
//         setError('Failed to fetch categories');
//         console.error('Failed to fetch categories:', error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCategories();
//   }, []);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>{error}</p>;

//   return (
//     <div>
//       <div className="modal fade bg-white" id="templatemo_search" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
//         <div className="modal-dialog modal-lg" role="document">
//           <div className="w-100 pt-1 mb-5 text-right">
//             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//           </div>
//           <form action="" method="get" className="modal-content modal-body border-0 p-0">
//             <div className="input-group mb-2">
//               <input type="text" className="form-control" id="inputModalSearch" name="q" placeholder="Search ..." />
//               <button type="submit" className="input-group-text bg-success text-light">
//                 <i className="fa fa-fw fa-search text-white"></i>
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//       <div id="template-mo-zay-hero-carousel" className="carousel slide" data-bs-ride="carousel">
//         <ol className="carousel-indicators">
//           <li data-bs-target="#template-mo-zay-hero-carousel" data-bs-slide-to="0" className="active"></li>
//           <li data-bs-target="#template-mo-zay-hero-carousel" data-bs-slide-to="1"></li>
//           <li data-bs-target="#template-mo-zay-hero-carousel" data-bs-slide-to="2"></li>
//         </ol>
//         <div className="carousel-inner">
//           <div className="carousel-item active">
//             <div className="container">
//               <div className="row p-5">
//                 <div className="mx-auto col-md-8 col-lg-4 order-lg-last">
//                   <img className="img-fluid bimg" src={bnrimgone} alt="" />
//                 </div>
//                 <div className="col-lg-8 mb-0 d-flex align-items-center">
//                   <div className="text-align-left align-self-center">
//                     <h1 className="h1 text-success"><b>Prachiz</b> Masale</h1>
//                     <h3 className="h2">Hand made in Pune with all natural ingredients</h3>
//                     <p>
//                       Prachiz... Spices is the home of our range of products which are made from premium quality gins giving our food natural color, aroma and amazing taste, our products are made in traditional method in clean environment. So every dish becomes tasty and gives a pleasant experience!
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="carousel-item">
//             <div className="container">
//               <div className="row p-5">
//                 <div className="mx-auto col-md-8 col-lg-4 order-lg-last">
//                   <img className="img-fluid" src="./assets/img/RDBS imgs/banner_img_02.jpg" alt="" />
//                 </div>
//                 <div className="col-lg-8 mb-0 d-flex align-items-center">
//                   <div className="text-align-left">
//                     <h1 className="h1">Proident occaecat</h1>
//                     <h3 className="h2">Aliquip ex ea commodo consequat</h3>
//                     <p>
//                       You are permitted to use this Zay CSS template for your commercial websites.
//                       You are <strong>not permitted</strong> to re-distribute the template ZIP file in any kind of template collection websites.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="carousel-item">
//             <div className="container">
//               <div className="row p-5">
//                 <div className="mx-auto col-md-8 col-lg-4 order-lg-last">
//                   <img className="img-fluid" src={bnrimgthree} alt="" />
//                 </div>
//                 <div className="col-lg-8 mb-0 d-flex align-items-center">
//                   <div className="text-align-left">
//                     <h1 className="h1">Repr in voluptate</h1>
//                     <h3 className="h2">Ullamco laboris nisi ut</h3>
//                     <p>
//                       We bring you 100% free CSS templates for your websites.
//                       If you wish to support TemplateMo, please make a small contribution via PayPal or tell your friends about our website. Thank you.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <a className="carousel-control-prev text-decoration-none w-auto ps-3" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="prev">
//           <i className="fas fa-chevron-left"></i>
//         </a>
//         <a className="carousel-control-next text-decoration-none w-auto pe-3" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="next">
//           <i className="fas fa-chevron-right"></i>
//         </a>
//       </div>
//       <section className="container py-5">
//         <div className="row text-center pt-3">
//           <div className="col-lg-6 m-auto">
//             <h1 className="h1">Categories of Products</h1>
//             <p>
//               Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
//               deserunt mollit anim id est laborum.
//             </p>
//           </div>
//         </div>

//         {/* Scrollable categories container */}
//         <div className="category-container">
//           {categories.length > 0 ? (
//             categories.map((category) => (
//               <div key={category.PROD_ID} className="category-item hover-card ">
//                 <CardMedia
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     height: "150px",
//                   }}
//                 >
//                   <ProductImage
//                     imagePath={category.PROD_IMG_URL}
//                     style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "cover" }}
//                   />
//                 </CardMedia>
//                 <h5 className="text-center mt-3 mb-3">{category.PROD_TYP_ID}</h5>
//                 <p className="text-center">
//                   <Button
//                     component={Link}
//                     to={`/shop?category=${category.PROD_TYP_ID}`}
//                     sx={{
//                       mt: 1,
//                       padding: "1px 8px", // Adjust padding for a smaller button
//                       minWidth: "40px", // Reduce button width
//                       fontSize: "0.8rem", // Reduce font size
//                       background: "linear-gradient(90deg, #00c6ff, #0072ff)",
//                       '&:hover': {
//                         background: "linear-gradient(90deg, #0072ff, #00c6ff)",
//                         color: "#fff",
//                       },
//                       color: "#fff",
//                     }}
//                     variant="contained"
//                   >
//                     Go Shop
//                   </Button>

//                 </p>


//               </div>
//             ))
//           ) : (
//             <p>No categories found.</p>
//           )}
//         </div>
//       </section>

//     </div>
//   );
// }


import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import services from "./apiServices";
import bnrimgone from "../img/RDBS imgs/banner_img_01_resized.png";
import bnrimgthree from "../img/banner_img_03.jpg";
import ProductImage from "./Productimage";
import { CardMedia, Button, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export default function Home() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const prodTypeId = "Vegetables";
        const response = await services.fetchProductsByCategory(prodTypeId);

        console.log("Response:", response);

        if (Array.isArray(response)) {
          setCategories(response);
        } else {
          setError("Unexpected response format");
        }
      } catch (error) {
        setError("Failed to fetch categories");
        console.error("Failed to fetch categories:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      {/* Carousel Section */}
      <div id="template-mo-zay-hero-carousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="row p-5">
                <div className="mx-auto col-md-8 col-lg-4 order-lg-last">
                  <img className="img-fluid bimg" src={bnrimgone} alt="" />
                </div>
                <div className="col-lg-8 d-flex align-items-center">
                  <div>
                    <h1 className="h1 text-success"><b>Prachiz</b> Masale</h1>
                    <h3 className="h2">Hand made in Pune with all natural ingredients</h3>
                    <p>Prachiz... Spices is the home of our range of products which are made from premium quality gins giving our food natural color, aroma, and amazing taste.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Additional Carousel Items */}
          <div className="carousel-item">
            <div className="container">
              <div className="row p-5">
                <div className="mx-auto col-md-8 col-lg-4 order-lg-last">
                  <img className="img-fluid" src={bnrimgthree} alt="" />
                </div>
                <div className="col-lg-8 d-flex align-items-center">
                  <div>
                    <h1 className="h1">Repr in voluptate</h1>
                    <h3 className="h2">Ullamco laboris nisi ut</h3>
                    <p>We bring you 100% free CSS templates for your websites.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Carousel Controls */}
        <a className="carousel-control-prev text-decoration-none w-auto ps-3" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="prev">
          <i className="fas fa-chevron-left"></i>
        </a>
        <a className="carousel-control-next text-decoration-none w-auto pe-3" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="next">
          <i className="fas fa-chevron-right"></i>
        </a>
      </div>

      {/* Categories Section */}
      <section className="container py-5">
        <div className="row text-center pt-3">
          <div className="col-lg-6 m-auto">
            <h1 className="h1">Categories of Products</h1>
            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>
        {/* Category Slider Component */}
        <CategorySlider categories={categories} />
      </section>
    </div>
  );
}

// Category Slider Component
const CategorySlider = ({ categories }) => {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = 300;
      scrollRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="category-slider">
      {/* Left Arrow */}
      <IconButton className="arrow-btn left-arrow" onClick={() => scroll("left")}>
        <ChevronLeft />
      </IconButton>

      {/* Scrollable Container */}
      <div className="category-scroll-container" ref={scrollRef}>
        {categories.length > 0 ? (
          categories.map((category) => (
            <div key={category.PROD_ID} className="category-item hover-card">
              <CardMedia className="category-image">
                <ProductImage imagePath={category.PROD_IMG_URL} className="product-image" />
              </CardMedia>
              <h5 className="text-center mt-3 mb-3">{category.PROD_TYP_ID}</h5>
              <p className="text-center">
                <Button component={Link} to={`/shop?category=${category.PROD_TYP_ID}`} className="shop-button">
                  Shop
                </Button>
              </p>
            </div>
          ))
        ) : (
          <p>No categories found.</p>
        )}
      </div>

      {/* Right Arrow */}
      <IconButton className="arrow-btn right-arrow" onClick={() => scroll("right")}>
        <ChevronRight />
      </IconButton>
    </div>
  );
};

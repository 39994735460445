import React from 'react';
import { Button, TextField, Grid, Typography, Container, Paper, Box } from '@mui/material';

export default function Contact() {
  return (
    <>
      {/* Start Content Page */}
      <Container maxWidth="lg" className="contact-container">
        <Grid container justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h3" component="h1" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              Lorem ipsum dolor sit amet.
            </Typography>
          </Grid>
        </Grid>

        {/* Start Contact Form */}
        <Paper className="contact-form-paper">
          <form method="post" role="form">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField label="Name" variant="outlined" fullWidth required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Email" variant="outlined" fullWidth required type="email" />
              </Grid>

              <Grid item xs={12}>
                <TextField label="Subject" variant="outlined" fullWidth required />
              </Grid>

              <Grid item xs={12}>
                <TextField label="Message" variant="outlined" fullWidth multiline rows={6} required />
              </Grid>

              <Grid item xs={12} className="contact-button-container">
                <Button variant="contained" color="primary" size="large" type="submit" className="contact-button">
                  Let’s Talk
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SmallProductImage from './smallproductimg';
import services from './apiServices';
import upiImage from '../img/upiscan.jpg';
import generateCartOrderNumber from './gencartordrfunforpurchase';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    TextField, Paper, Typography, Stepper, Step, StepLabel, Container, Grid, Box,
    Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Card, CardContent, CardMedia
} from '@mui/material';

const ProductPurchase = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedProducts = location.state?.selectedProducts || [];
    const [cartProducts, setCartProducts] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const [addresses, setAddresses] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
    const [orderStatus, setOrderStatus] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');
    const [editAddress, setEditAddress] = useState({
        id: '',
        displayName: '',
        cellPh: '',
        addLn1: '',
        addLn2: '',
        addLn3: '',
        addCt: '',
        addPinZip: '',
    });

    const calculateTotalQuantity = () => {
        return selectedProducts.reduce((total, product) => total + (product.quantity || 0), 0);
    }

    // Order confirmation details
    const [orderDetails, setOrderDetails] = useState({
        ORDR_DLVRY_CLAN_NO: `ORD-${Math.floor(Math.random() * 1000000)}`,
        PAY_REF_NO: '',
        ORDR_DLVRY_DT: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10), // 7 days from now in YYYY-MM-DD format
        ORDR_PYMT_MOD: 'upi',
        ORDR_PYMT_STTS: 'Payment_Received',
        ORDR_DLVRY_MOD: 'Home_Delivery',
        ORDR_PYMT_DT: new Date().toISOString().slice(0, 10), // Today's date in YYYY-MM-DD format
        ORDR_DLVRY_ITM_CNT: calculateTotalQuantity(),
        ORDR_TOT_AMT_RCVD: '',
        USR_REF_INV_NO: '',
        USR_REF_INV_DT: new Date().toISOString().slice(0, 19).replace('T', ' '), // Format the order reference date correctly,
        ORDR_STTS: 'Confirmed',
        ORDR_SHIPNG_ADD: '',
        ORDR_BILNG_ADD: '',
        GNRTD_ORD_NO: '',
    });

    useEffect(() => {
        const fetchUserSession = async () => {
            try {
                const sessionData = await services.getUserSession();
                if (sessionData.firstName && sessionData.lastName) {
                    const orderNumber = generateCartOrderNumber(sessionData.firstName, sessionData.lastName);

                    setOrderDetails(prevState => ({
                        ...prevState,
                        USR_REF_INV_NO: orderNumber,
                        GNRTD_ORD_NO: orderNumber,
                    }));
                }

                // Calculate and update total amount and item count
                const quantity = calculateTotalQuantity();
                const totalAmount = calculateTotalAmount();

                setOrderDetails(prevState => ({
                    ...prevState,
                    ORDR_DLVRY_ITM_CNT: quantity,
                    ORDR_TOT_AMT_RCVD: totalAmount.toFixed(2),
                }));
            } catch (error) {
                console.error('Error fetching user session:', error);
            }
        };

        fetchUserSession();
    }, []);


    const handleSubmit = async () => {

        const quantity = calculateTotalQuantity();
        const productPrice = calculateTotalAmount();
        const totalAmount = productPrice * quantity

        // Validate payment method
        if (!paymentMethod) {
            console.error('Payment method is required');
            return;
        }

        // Check if PAY_REF_NO is provided or generate it
        let payRefNo = orderDetails.PAY_REF_NO;
        if (!payRefNo) {
            payRefNo = Math.floor(100000000000 + Math.random() * 900000000000);  // Generate PAY_REF_NO if not provided
        }

        // Prepare data for submission
        const orderData = {
            ORDR_DLVRY_CLAN_NO: `ORD-${Math.floor(Math.random() * 1000000)}`,
            PAY_REF_NO: payRefNo,
            ORDR_DLVRY_DT: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10), // 7 days from now in YYYY-MM-DD format
            ORDR_PYMT_MOD: paymentMethod,
            ORDR_PYMT_STTS: 'Payment_Received',
            ORDR_DLVRY_MOD: 'Home_Delivery',
            ORDR_PYMT_DT: orderDetails.ORDR_PYMT_DT || new Date().toLocaleDateString(),
            ORDR_DLVRY_ITM_CNT: quantity,
            ORDR_TOT_AMT_RCVD: orderDetails.ORDR_TOT_AMT_RCVD || totalAmount,
            USR_REF_INV_NO: orderDetails.USR_REF_INV_NO, // Generated Order Number
            USR_REF_INV_DT: new Date().toISOString().slice(0, 19).replace('T', ' '), // Format the order reference date correctly
            ORDR_STTS: 'Confirmed',
            PROD_PRICE: orderDetails.ORDR_TOT_AMT_RCVD || totalAmount,  // Replace with actual price
            quantity: quantity,     // Replace with actual quantity
            ORDR_SHIPNG_ADD: orderDetails.ORDR_SHIPNG_ADD, // Shipping address from state
            ORDR_BILNG_ADD: orderDetails.ORDR_BILNG_ADD, // Billing address from state
            GNRTD_ORD_NO: orderDetails.GNRTD_ORD_NO,
            // Add other order details as necessary
        };

        console.log("Submitting order data:", orderData);
        try {
            const result = await services.buyProductFrompurchase(orderData);

            if (result.success) {
                setOrderStatus(`Order placed successfully! Order ID: ${result.orderHeaderId}`);
            } else {
                setOrderStatus('Failed to place order. Please try again.');
            }

            // const fetchedOrderDetails = await services.getOrderDetails(result.ORDR_DLVRY_CLAN_NO);
            // console.log('Fetched order details:', fetchedOrderDetails);
            // setOrderDetails(fetchedOrderDetails);
        } catch (error) {
            console.error('Error during order submission:', error);
        }
    };



    const steps = ['Cart', 'Delivery Address', 'Payment Options', 'Review Order', 'Order Details'];


    useEffect(() => {
        // Fetch cart products
        services.viewcartprodinpurchase().then(response => {
            setCartProducts(response || []);
        }).catch(error => {
            console.error('Error fetching cart products:', error);
        });

        // Fetch addresses from the server
        const fetchAddresses = async () => {
            try {
                const data = await services.getAddresses();
                setAddresses(data);
            } catch (err) {
                console.error('Failed to fetch addresses', err);
            }
        };

        fetchAddresses();
    }, []);

    const calculateTotalAmount = () => {
        return selectedProducts.reduce((total, product) => total + (product.PROD_PRICE * product.quantity), 0);
    };


    const handleOpenDialog = (address) => {
        setEditAddress({
            id: address.NTT_KY,
            displayName: address.USR_DISPLAY_NAME,
            cellPh: address.USR_CELL_PH,
            addLn1: address.ADD_LN1,
            addLn2: address.ADD_LN2,
            addLn3: address.ADD_LN3,
            addCt: address.ADD_CT,
            addPinZip: address.ADD_PIN_ZIP,
        });
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditAddress(prev => ({ ...prev, [name]: value }));
    };


    const handleSaveEdit = async () => {
        try {
            // Check if the address is being updated or added
            if (editAddress.id) {
                // Update existing address
                await services.updateUserAddress(editAddress);
            } else {
                // Insert new address
                await services.insertUserAddress(editAddress);
            }

            // Close the dialog after successful update
            setIsDialogOpen(false);

            // Refetch the updated list of addresses
            const updatedAddresses = await services.getAddresses();
            setAddresses(updatedAddresses);
        } catch (error) {
            console.error('Error saving address:', error);
        }
    };

    const handleExited = () => {
        // Reset the edit address state when the dialog is closed
        setEditAddress({
            id: '',
            displayName: '',
            cellPh: '',
            addLn1: '',
            addLn2: '',
            addLn3: '',
            addCt: '',
            addPinZip: '',
        });
    };

    const handleAddressChange = (event) => {
        const selectedValue = event.target.value; // This is the ADD_KY of the selected address
        console.log("Selected ADD_KY:", selectedValue); // Check the selected value

        // Update the selectedAddress state with the selected ADD_KY
        setSelectedAddress(selectedValue);

        // Find the selected address using ADD_KY
        // const selectedAddress = addresses.find(address => address.ADD_KY === selectedValue);
        const selectedAddress = addresses.filter(address => address.ADD_KY == selectedValue)[0];
        console.log("Selected Address:", selectedAddress); // Verify the selected address object

        // Update order details with the selected address key (ADD_KY)
        if (selectedAddress) {
            setOrderDetails(prevState => ({
                ...prevState,
                ORDR_SHIPNG_ADD: selectedAddress.ADD_KY,  // Set the selected address key for shipping
                ORDR_BILNG_ADD: selectedAddress.ADD_KY     // Set the selected address key for billing (if it's the same)
            }));
        } else {
            setOrderDetails(prevState => ({
                ...prevState,
                ORDR_SHIPNG_ADD: null,  // Set null if no address is selected
                ORDR_BILNG_ADD: null    // Set null if no address is selected
            }));
        }

    };

    const downloadInvoice = async () => {
        // Prepare the customer details using state
        const customerDetails = {
            name: selectedAddress.USR_DISPLAY_NAME,
            email: orderDetails.customerEmail,
            address: `${addresses.ADD_LN1}, ${addresses.ADD_LN2}, ${addresses.ADD_LN3}, ${addresses.ADD_CT}, ${addresses.ADD_PIN_ZIP}`
        };

        // Prepare the product details using the selected products
        const productDetails = selectedProducts.map(product => ({
            name: product.PROD_DESP,
            price: product.PROD_PRICE,
            quantity: product.quantity
        }));

        // Calculate total amount based on product details
        const totalAmount = productDetails.reduce((total, product) => total + (product.price * product.quantity), 0);

        try {
            console.log("hello from downloadInvoice");

            // Fetch the PDF from the server
            const response = await fetch('/api/Generateinvoice/generate-invoice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerDetails,
                    productDetails,
                    totalAmount
                }), // Send all data as a single object to the backend
            });

            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Open the PDF in a new window or tab
                window.open(url, '_blank');
            } else {
                console.error('Error generating invoice:', await response.text());
            }
        } catch (error) {
            console.error('Error fetching PDF:', error);
            alert('There was an issue generating the invoice.');
        }
    };


    const renderAddressSelection = () => (
        // <div><br></br>
        //     <h2>Select or Edit Address</h2>
        //     <FormControl component="fieldset">
        //         <FormLabel component="legend">Select Delivery Address</FormLabel>
        //         <RadioGroup
        //             aria-label="delivery-address"
        //             name="delivery-address"
        //             value={selectedAddress} // Bind to the selectedAddress state
        //             onChange={handleAddressChange}
        //         >
        //             {addresses.length === 0 ? (
        //                 <p>No addresses found.</p>
        //             ) : (
        //                 addresses.map((address) => (
        //                     <Box key={address.ADD_KY} display="flex" alignItems="center" mb={2}>
        //                         <FormControlLabel
        //                             value={address.ADD_KY} // Use ADD_KY as the value
        //                             control={<Radio />}
        //                             label={`${address.IS_ADD_TYP}, ${address.USR_DISPLAY_NAME}, ${address.USR_CELL_PH}, ${address.ADD_LN1}, ${address.ADD_LN2}, ${address.ADD_LN3}, ${address.ADD_CT}, ${address.ADD_PIN_ZIP}`}
        //                         />
        //                         {/* <Button
        //                             variant="outlined"
        //                             color="secondary"
        //                             onClick={() => handleOpenDialog(address)}
        //                             style={{ marginLeft: '10px' }}
        //                         >
        //                             Edit
        //                         </Button> */}
        //                     </Box>
        //                 ))
        //             )}
        //         </RadioGroup>
        //     </FormControl>
        // </div>
        <div className="address-container">
            <h2>Select or Edit Address</h2>
            <FormControl component="fieldset">
                <FormLabel component="legend">Select Delivery Address</FormLabel>
                <RadioGroup
                    aria-label="delivery-address"
                    name="delivery-address"
                    value={selectedAddress}
                    onChange={handleAddressChange}
                >
                    {addresses.length === 0 ? (
                        <p>No addresses found.</p>
                    ) : (
                        addresses.map((address) => (
                            <Box key={address.ADD_KY} className="address-box">
                                <FormControlLabel
                                    value={address.ADD_KY}
                                    control={<Radio />}
                                    label={`${address.IS_ADD_TYP}, ${address.USR_DISPLAY_NAME}, ${address.USR_CELL_PH}, ${address.ADD_LN1}, ${address.ADD_LN2}, ${address.ADD_LN3}, ${address.ADD_CT}, ${address.ADD_PIN_ZIP}`}
                                />
                            </Box>
                        ))
                    )}
                </RadioGroup>
            </FormControl>
        </div>
    );

    const renderDialog = () => (
        <Dialog open={isDialogOpen} onClose={handleCloseDialog} onExited={handleExited}>
            <DialogTitle>Edit Address</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Modify the address details below and click "Save" to update the address.
                </DialogContentText>
                <TextField
                    label="Display Name"
                    name="displayName"
                    value={editAddress.displayName}
                    onChange={handleEditChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Cell Phone"
                    name="cellPh"
                    value={editAddress.cellPh}
                    onChange={handleEditChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Address Line 1"
                    name="addLn1"
                    value={editAddress.addLn1}
                    onChange={handleEditChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Address Line 2"
                    name="addLn2"
                    value={editAddress.addLn2}
                    onChange={handleEditChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Address Line 3"
                    name="addLn3"
                    value={editAddress.addLn3}
                    onChange={handleEditChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="City"
                    name="addCt"
                    value={editAddress.addCt}
                    onChange={handleEditChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Pin/Zip Code"
                    name="addPinZip"
                    value={editAddress.addPinZip}
                    onChange={handleEditChange}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSaveEdit} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renderCartSummary = () => (
        <Box className="purchase-container">
            <Typography variant="h4" gutterBottom>
                Purchase Summary
            </Typography>
            <Grid item xs={12} sm={6} md={6} className="summary-header">
                <Typography variant="h6" gutterBottom>
                    Product Count: {selectedProducts.length}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Total Amount: ₹ {calculateTotalAmount().toFixed(2)}
                </Typography>
            </Grid>
            {selectedProducts.length === 0 ? (
                <div className="purchase-empty">No products to display</div>
            ) : (
                <Grid container spacing={3}>
                    {selectedProducts.map((product, index) => (
                        <Grid item xs={6} sm={4} md={3} key={index}>
                            <Card className="product-card">
                                <SmallProductImage
                                    imagePath={product.PROD_IMG_URL}
                                    alt={product.PROD_DESP}
                                />
                                <CardContent>
                                    <Typography variant="h6" component="div" noWrap>
                                        {product.PROD_DESP}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Price: ₹ {product.PROD_PRICE}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Quantity: {product.quantity}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" className="total-amount">
                                        Total: ₹ {(product.PROD_PRICE * product.quantity).toFixed(2)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );

    const renderReviewOrder = () => (
        // <div><br></br>
        //     <h2>Review Your Order</h2>

        //     {/* <Typography variant="h6">Delivery Address</Typography>
        //     <Typography variant="body1">
        //         {/* {addresses.find((addr) => addr.IS_ADD_TYP === selectedAddress)?.USR_DISPLAY_NAME}, {selectedAddress} */}
        //     {/* </Typography> */}

        //     <Grid container spacing={2} mt={2}>
        //         <Grid item xs={6} sm={6}>
        //             <Typography variant="h6">UPI Payment Image:</Typography>
        //             <img className="upiimg" src={upiImage} alt="UPI Payment" />
        //         </Grid>


        //     </Grid>

        //     {/* Order Details Form */}
        //     <Box mt={3}>
        //         <Typography variant="h6">Order Details</Typography>
        //         <TextField
        //             label="Payment Reference No"
        //             name="PAY_REF_NO"
        //             value={orderDetails.PAY_REF_NO}
        //             onChange={(e) => setOrderDetails({ ...orderDetails, PAY_REF_NO: e.target.value })}
        //             fullWidth
        //             margin="normal"
        //         />
        //         <TextField
        //             label="Payment Date"
        //             name="ORDR_PYMT_DT"
        //             value={orderDetails.ORDR_PYMT_DT}
        //             onChange={(e) => setOrderDetails({ ...orderDetails, ORDR_PYMT_DT: e.target.value })}
        //             fullWidth
        //             margin="normal"
        //             type="date"
        //             InputLabelProps={{ shrink: true }}
        //         />
        //         <TextField
        //             label="Total Amount Paid"
        //             name="ORDR_TOT_AMT_RCVD"
        //             value={orderDetails.ORDR_TOT_AMT_RCVD}
        //             onChange={(e) => setOrderDetails({ ...orderDetails, ORDR_TOT_AMT_RCVD: e.target.value })}
        //             fullWidth
        //             margin="normal"
        //             type="number"
        //         />

        //         {/* Add more fields as needed */}
        //         {/* Show error message if validation fails */}
        //         {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        //     </Box>
        // </div>
        <div className="review-container">
            <h2>Review Your Order</h2>

            <Grid container spacing={2} className="grid-container">
                <Grid item xs={6} sm={6}>
                    <Typography variant="h6">UPI Payment Image:</Typography>
                    <img className="upi-img" src={upiImage} alt="UPI Payment" />
                </Grid>
            </Grid>

            <Box className="order-details-box">
                <Typography variant="h6">Order Details</Typography>
                <TextField
                    label="Payment Reference No"
                    name="PAY_REF_NO"
                    value={orderDetails.PAY_REF_NO}
                    onChange={(e) => setOrderDetails({ ...orderDetails, PAY_REF_NO: e.target.value })}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Payment Date"
                    name="ORDR_PYMT_DT"
                    value={orderDetails.ORDR_PYMT_DT}
                    onChange={(e) => setOrderDetails({ ...orderDetails, ORDR_PYMT_DT: e.target.value })}
                    fullWidth
                    margin="normal"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Total Amount Paid"
                    name="ORDR_TOT_AMT_RCVD"
                    value={orderDetails.ORDR_TOT_AMT_RCVD}
                    onChange={(e) => setOrderDetails({ ...orderDetails, ORDR_TOT_AMT_RCVD: e.target.value })}
                    fullWidth
                    margin="normal"
                    type="number"
                />

                {errorMessage && <Typography className="error-message">{errorMessage}</Typography>}
            </Box>
        </div>
    );

    const renderOrderConfirmation = () => (
        // <Paper elevation={3} style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
        //     <Typography variant="h4" gutterBottom align="center">Order Details</Typography>
        //     <Typography variant="h5" align="center" color="primary">Thank you for your order!</Typography>
        //     <Typography variant="body1" align="center" gutterBottom>
        //         Your order has been successfully placed.
        //     </Typography>

        //     <Grid container spacing={2} style={{ marginTop: "20px" }}>
        //         <Grid item xs={6}>
        //             <Typography variant="h6">Order Number:</Typography>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <Typography variant="body1">{orderDetails.ORDR_DLVRY_CLAN_NO}</Typography>
        //         </Grid>

        //         <Grid item xs={6}>
        //             <Typography variant="h6">Payment Reference:</Typography>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <Typography variant="body1">{orderDetails.PAY_REF_NO}</Typography>
        //         </Grid>

        //         <Grid item xs={6}>
        //             <Typography variant="h6">Delivery Date:</Typography>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <Typography variant="body1">{orderDetails.ORDR_DLVRY_DT}</Typography>
        //         </Grid>

        //         <Grid item xs={6}>
        //             <Typography variant="h6">Order Payment Mode:</Typography>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <Typography variant="body1">{orderDetails.ORDR_PYMT_MOD}</Typography>
        //         </Grid>

        //         <Grid item xs={6}>
        //             <Typography variant="h6">Order Payment Status:</Typography>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <Typography variant="body1">{orderDetails.ORDR_PYMT_STTS}</Typography>
        //         </Grid>

        //         <Grid item xs={6}>
        //             <Typography variant="h6">Order Delivery Mode:</Typography>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <Typography variant="body1">{orderDetails.ORDR_DLVRY_MOD}</Typography>
        //         </Grid>

        //         <Grid item xs={6}>
        //             <Typography variant="h6">Payment Date:</Typography>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <Typography variant="body1">{orderDetails.ORDR_PYMT_DT}</Typography>
        //         </Grid>

        //         <Grid item xs={6}>
        //             <Typography variant="h6">Product Count:</Typography>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <Typography variant="body1">{orderDetails.ORDR_DLVRY_ITM_CNT}</Typography>
        //         </Grid>

        //         <Grid item xs={6}>
        //             <Typography variant="h6">Total Amount Paid:</Typography>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <Typography variant="body1" color="secondary">₹ {orderDetails.ORDR_TOT_AMT_RCVD}</Typography>
        //         </Grid>
        //     </Grid>
        // </Paper>
        <Paper elevation={3} className="order-details-container">
            <Typography variant="h4" gutterBottom align="center">Order Details</Typography>
            <Typography variant="h5" align="center" color="primary">Thank you for your order!</Typography>
            <Typography variant="body1" align="center" gutterBottom>
                Your order has been successfully placed.
            </Typography>

            <Grid container spacing={2} className="order-grid">
                <Grid item xs={6}>
                    <Typography variant="h6">Order Number:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{orderDetails.ORDR_DLVRY_CLAN_NO}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Payment Reference:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{orderDetails.PAY_REF_NO}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Delivery Date:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{orderDetails.ORDR_DLVRY_DT}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Order Payment Mode:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{orderDetails.ORDR_PYMT_MOD}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Order Payment Status:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{orderDetails.ORDR_PYMT_STTS}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Order Delivery Mode:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{orderDetails.ORDR_DLVRY_MOD}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Payment Date:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{orderDetails.ORDR_PYMT_DT}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Product Count:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{orderDetails.ORDR_DLVRY_ITM_CNT}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Total Amount Paid:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" color="secondary">₹ {orderDetails.ORDR_TOT_AMT_RCVD}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );

    const handleNext = async () => {
        if (currentStep === 1 && !selectedAddress) {
            alert('Please select an address');
            return;
        }
        if (currentStep === 2 && !paymentMethod) {
            alert('Please select a payment method');
            return;
        }

        // If on Review Order step, check for required fields before calling handleSubmit
        if (currentStep === 3) {
            const requiredFields = [
                orderDetails.PAY_REF_NO,
                orderDetails.ORDR_TOT_AMT_RCVD,
                orderDetails.ORDR_PYMT_DT,
            ];

            // Check if any required field is missing
            const missingFields = requiredFields.some(field => !field);
            if (missingFields) {
                alert('Please fill in all required fields before placing your order.');
                return;
            }

            // Call handleSubmit to insert data into the backend
            await handleSubmit();
        }

        setCurrentStep((prevStep) => prevStep + 1); // Proceed to the next step
    };

    const handleBack = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    // const handlePlaceOrder = () => {
    //     alert('Order placed successfully!');
    //     navigate('/');
    // };

    const handleGoToShop = () => {
        navigate('/shop'); // Replace '/shop' with the actual route for your shop page
    };

    return (
        // <Container maxWidth="md" style={{ marginTop: '2rem' }}>
        //     <Paper elevation={3} style={{ padding: '2rem' }}>
        //         <Stepper activeStep={currentStep}>
        //             {steps.map((label, index) => (
        //                 <Step key={index}>
        //                     <StepLabel>{label}</StepLabel>
        //                 </Step>
        //             ))}
        //         </Stepper>

        //         {currentStep === 0 && renderCartSummary()}
        //         {currentStep === 1 && renderAddressSelection()}
        //         {currentStep === 2 && (
        //             <div><br></br>
        //                 <h2>Select Payment Option</h2>
        //                 <FormControl component="fieldset">
        //                     <FormLabel component="legend">Payment Method</FormLabel>
        //                     <RadioGroup
        //                         aria-label="payment-method"
        //                         name="payment-method"
        //                         value={paymentMethod}
        //                         onChange={(event) => setPaymentMethod(event.target.value)}
        //                     >
        //                         <FormControlLabel value="credit-card" control={<Radio />} label="Credit Card" />
        //                         <FormControlLabel value="debit-card" control={<Radio />} label="Debit Card" />
        //                         <FormControlLabel value="upi" control={<Radio />} label="UPI" />
        //                         <FormControlLabel value="net-banking" control={<Radio />} label="Net Banking" />
        //                     </RadioGroup>
        //                 </FormControl>
        //             </div>
        //         )}

        //         {currentStep === 3 && renderReviewOrder()} {/* New Review Order Step */}
        //         {currentStep === 4 && renderOrderConfirmation()}

        //         {renderDialog()}

        //         <Box mt={2}>
        //             {currentStep > 0 && currentStep < steps.length - 1 && (
        //                 <Button sx={{
        //                     mt: 1,
        //                     background: "linear-gradient(90deg, #ff4e50, #ff6a00)", // Red gradient
        //                     '&:hover': {
        //                         background: "linear-gradient(90deg, #ff6a00, #ff4e50)", // Inverse gradient
        //                     },
        //                     color: "#fff",
        //                 }} variant="contained" color="primary" onClick={handleBack} style={{ marginRight: '1rem' }}>
        //                     Back
        //                 </Button>
        //             )}

        //             {currentStep < steps.length - 2 ? ( // Show "Next" for steps before the review step
        //                 <Button sx={{
        //                     mt: 1,
        //                     background: "linear-gradient(90deg, #00c6ff, #0072ff)",
        //                     '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
        //                     color: "#fff",
        //                 }} variant="contained" color="primary" onClick={handleNext}>
        //                     Next
        //                 </Button>
        //             ) : currentStep === steps.length - 2 ? ( // Show "Place Order" on the second last step
        //                 <Button sx={{
        //                     mt: 1,
        //                     background: "linear-gradient(90deg, #00c6ff, #0072ff)",
        //                     '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
        //                     color: "#fff",
        //                 }} variant="contained" color="primary" onClick={handleNext}>
        //                     Place Order
        //                 </Button>
        //             ) : ( // Show "Download Invoice" + "Go to Shop" on the last step
        //                 <>
        //                     <Button sx={{
        //                         mt: 1,
        //                         background: "linear-gradient(90deg, #00c6ff, #0072ff)",
        //                         '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
        //                         color: "#fff",
        //                         marginRight: '1rem',
        //                     }} variant="contained" color="primary" onClick={downloadInvoice}>
        //                         Download Invoice
        //                     </Button>

        //                     <Button sx={{
        //                         mt: 1,
        //                         background: "linear-gradient(90deg, #4caf50, #2e7d32)", // Green gradient
        //                         '&:hover': { background: "linear-gradient(90deg, #2e7d32, #4caf50)" },
        //                         color: "#fff",
        //                     }} variant="contained" color="primary" onClick={handleGoToShop}>
        //                         Go to Shop
        //                     </Button>
        //                 </>
        //             )}
        //         </Box>

        //         <Box style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
        //             <Typography variant="h5">Total Amount: ₹ {calculateTotalAmount().toFixed(2)}</Typography>
        //             {message && <div className="alert alert-success">{message}</div>} {/* Message Display */}
        //         </Box>
        //     </Paper>
        // </Container>
        <Container maxWidth="md" className="checkout-container">
            <Paper elevation={3} className="checkout-paper">
                <Stepper activeStep={currentStep}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {currentStep === 0 && renderCartSummary()}
                {currentStep === 1 && renderAddressSelection()}
                {currentStep === 2 && (
                    <div className="payment-option">
                        <h2>Select Payment Option</h2>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Payment Method</FormLabel>
                            <RadioGroup
                                aria-label="payment-method"
                                name="payment-method"
                                value={paymentMethod}
                                onChange={(event) => setPaymentMethod(event.target.value)}
                            >
                                <FormControlLabel value="credit-card" control={<Radio />} label="Credit Card" />
                                <FormControlLabel value="debit-card" control={<Radio />} label="Debit Card" />
                                <FormControlLabel value="upi" control={<Radio />} label="UPI" />
                                <FormControlLabel value="net-banking" control={<Radio />} label="Net Banking" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                )}

                {currentStep === 3 && renderReviewOrder()}
                {currentStep === 4 && renderOrderConfirmation()}

                {renderDialog()}

                <Box mt={2} className="button-container">
                    {currentStep > 0 && currentStep < steps.length - 1 && (
                        <Button className="back-button" variant="contained" onClick={handleBack}>
                            Back
                        </Button>
                    )}

                    {currentStep < steps.length - 2 ? (
                        <Button className="next-button" variant="contained" onClick={handleNext}>
                            Next
                        </Button>
                    ) : currentStep === steps.length - 2 ? (
                        <Button className="next-button" variant="contained" onClick={handleNext}>
                            Place Order
                        </Button>
                    ) : (
                        <>
                            <Button className="download-button" variant="contained" onClick={downloadInvoice}>
                                Download Invoice
                            </Button>
                            <Button className="shop-button" variant="contained" onClick={handleGoToShop}>
                                Go to Shop
                            </Button>
                        </>
                    )}
                </Box>


                <Box className="total-amount-container">
                    <Typography variant="h5">Total Amount: ₹ {calculateTotalAmount().toFixed(2)}</Typography>
                    {message && <div className="alert alert-success">{message}</div>}
                </Box>
            </Paper>
        </Container>
    );
};

export default ProductPurchase;

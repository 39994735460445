import React, { useState } from 'react';
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import services from './apiServices';
import '../css/rdbs.css';


export default function Registrationuser() {
    const initialFormData = {
        firstName: '',
        middleName: '',
        lastName: '',
        displayName: '',
        email: '',
        phone: '',
        birthdate: '',
        gender: '',
        billingAddress1: '',
        billingAddress2: '',
        billingAddress3: '',
        billingAddresscity: '',
        billingAddresspin: '',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingAddress3: '',
        shippingAddresscity: '',
        shippingAddresspin: '',
        username: '',
        password: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [error, setError] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        displayName: '',
        email: '',
        phone: '',
        birthdate: '',
        gender: '',
        billingAddress1: '',
        billingAddress2: '',
        billingAddress3: '',
        billingAddresscity: '',
        billingAddresspin: '',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingAddress3: '',
        shippingAddresscity: '',
        shippingAddresspin: '',
        username: '',
        password: '',
        message: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        const [mainName, subName] = name.split('.');

        if (subName) {
            setFormData(prevState => ({
                ...prevState,
                [mainName]: {
                    ...prevState[mainName],
                    [subName]: value
                }
            }));
            setError(prevState => ({
                ...prevState,
                [mainName]: {
                    ...prevState[mainName],
                    [subName]: ''
                }
            }));
        } else {
            setFormData({ ...formData, [name]: value });
            setError({ ...error, [name]: '', message: '' });
            setSuccessMessage('');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formValid = true;
        const newErrorState = { ...error };

        // Validate first name
        if (formData.firstName.trim() === '') {
            newErrorState.firstName = 'First Name is required';
            formValid = false;
        }

        // Validate last name
        if (formData.lastName.trim() === '') {
            newErrorState.lastName = 'Last Name is required';
            formValid = false;
        }

        // Validate display name
        if (formData.displayName.trim() === '') {
            newErrorState.displayName = 'Display Name is required';
            formValid = false;
        }

        // Validate email
        if (formData.email.trim() === '') {
            newErrorState.email = 'Email is required';
            formValid = false;
        }

        // Validate phone
        if (formData.phone.trim() === '') {
            newErrorState.phone = 'Phone is required';
            formValid = false;
        }

        // Validate birthdate
        if (formData.birthdate.trim() === '') {
            newErrorState.birthdate = 'Birthdate is required';
            formValid = false;
        }

        // Validate billing address
        if (formData.billingAddress1.trim() === '') {
            newErrorState.billingAddress1 = 'Address Line 1 is required';
            formValid = false;
        }
        if (formData.billingAddress2.trim() === '') {
            newErrorState.billingAddress2 = 'Address Line 2 is required';
            formValid = false;
        }
        if (formData.billingAddress3.trim() === '') {
            newErrorState.billingAddress3 = 'Address Line 3 is required';
            formValid = false;
        }

        if (formData.billingAddresscity.trim() === '') {
            newErrorState.billingAddresscity = 'City is required';
            formValid = false;
        }
        if (formData.billingAddresspin.trim() === '') {
            newErrorState.billingAddresspin = 'PIN is required';
            formValid = false;
        }

        // Validate shipping address
        if (formData.shippingAddress1.trim() === '') {
            newErrorState.shippingAddress1 = 'Address Line 1 is required';
            formValid = false;
        }
        if (formData.shippingAddress2.trim() === '') {
            newErrorState.shippingAddress2 = 'Address Line 2 is required';
            formValid = false;
        }
        if (formData.shippingAddress3.trim() === '') {
            newErrorState.shippingAddress3 = 'Address Line 3 is required';
            formValid = false;
        }
        if (formData.shippingAddresscity.trim() === '') {
            newErrorState.shippingAddresscity = 'City is required';
            formValid = false;
        }
        if (formData.shippingAddresspin.trim() === '') {
            newErrorState.shippingAddresspin = 'PIN is required';
            formValid = false;
        }

        // Validate username
        if (formData.username.trim() === '') {
            newErrorState.username = 'Username is required';
            formValid = false;
        }

        // Validate password
        if (formData.password.trim() === '') {
            newErrorState.password = 'Password is required';
            formValid = false;
        }

        // Validate gender
        if (formData.gender === '') {
            newErrorState.gender = 'Please select your gender';
            formValid = false;
        }

        // If form is not valid, update error state and prevent submission
        if (!formValid) {
            setError(newErrorState);
            return;
        }

        // If form is valid, proceed with registration
        try {
            const res = await services.sendRegistrationData(formData);
            console.log(res); // Handle the response as needed

            // Optionally, reset the form after successful submission
            setFormData(initialFormData);
            setError({
                firstName: '',
                middleName: '',
                lastName: '',
                displayName: '',
                email: '',
                phone: '',
                birthdate: '',
                gender: '',
                billingAddress1: '',
                billingAddress2: '',
                billingAddress3: '',
                billingAddresscity: '',
                billingAddresspin: '',
                shippingAddress1: '',
                shippingAddress2: '',
                shippingAddress3: '',
                shippingAddresscity: '',
                shippingAddresspin: '',
                username: '',
                password: '',
                message: ''
            });
            setSuccessMessage('Registration successful!');
        } catch (error) {
            setError({ ...error, message: `Failed to register: ${error.message}` });
            setSuccessMessage('');
        }
    };

    return (
        <div>
            <section className="cd-hero">
                <ul className="cd-hero-slider">
                    <li className="selected">
                        <div className="heading">
                            <h1>Register</h1>
                            <span>Join Us</span>
                            <p>&nbsp;</p>
                        </div>
                        <div className="cd-full-width first-slide cd-hero-form">
                            <div className="container">
                                <div className="row row-custom">
                                    <div className="col-md-12 row-custom">
                                        <div className="content login-content login-custom">
                                            <h4 className='custom-label-login'>Register</h4>
                                            <form name="frm_register" id="frm_register" className="form-custom" onSubmit={handleSubmit}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>First Name:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="firstName"
                                                                label="First Name"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.firstName}
                                                                onChange={handleChange}
                                                            />
                                                            {error.firstName && <Alert severity="error">{error.firstName}</Alert>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Middle Name:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="middleName"
                                                                label="Middle Name"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.middleName}
                                                                onChange={handleChange}
                                                            />
                                                            {error.middleName && <Alert severity="error">{error.middleName}</Alert>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Last Name:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="lastName"
                                                                label="Last Name"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.lastName}
                                                                onChange={handleChange}
                                                            />
                                                            {error.lastName && <Alert severity="error">{error.lastName}</Alert>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Display Name:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="displayName"
                                                                label="Display Name"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.displayName}
                                                                onChange={handleChange}
                                                            />
                                                            {error.displayName && <Alert severity="error">{error.displayName}</Alert>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Email:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="email"
                                                                label="Email"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                            />
                                                            {error.email && <Alert severity="error">{error.email}</Alert>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Phone:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="phone"
                                                                label="Phone"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.phone}
                                                                onChange={handleChange}
                                                            />
                                                            {error.phone && <Alert severity="error">{error.phone}</Alert>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Birthdate:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="birthdate"
                                                                label="Birthdate"
                                                                type="date"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                InputLabelProps={{ shrink: true }}
                                                                value={formData.birthdate}
                                                                onChange={handleChange}
                                                            />
                                                            {error.birthdate && <Alert severity="error">{error.birthdate}</Alert>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Gender:</label> */}
                                                            <RadioGroup
                                                                row
                                                                name="gender"
                                                                value={formData.gender}
                                                                onChange={handleChange}
                                                            >
                                                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                                <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                            </RadioGroup>
                                                            {error.gender && <Alert severity="error">{error.gender}</Alert>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Billing Address:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="billingAddress1"
                                                                label="Billing Address Line 1"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.billingAddress1}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.billingAddress1 && <Alert severity="error">{error.billingAddress1}</Alert>}
                                                            <TextField
                                                                fullWidth
                                                                name="billingAddress2"
                                                                label="Billing Address Line 2"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.billingAddress2}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.billingAddress2 && <Alert severity="error">{error.billingAddress2}</Alert>}
                                                            <TextField
                                                                fullWidth
                                                                name="billingAddress3"
                                                                label="Billing Address Line 3"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.billingAddress3}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.billingAddress3 && <Alert severity="error">{error.billingAddress3}</Alert>}
                                                            <TextField
                                                                fullWidth
                                                                name="billingAddresscity"
                                                                label="Billing City"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.billingAddresscity}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.billingAddresscity && <Alert severity="error">{error.billingAddresscity}</Alert>}
                                                            <TextField
                                                                fullWidth
                                                                name="billingAddresspin"
                                                                label="Billing PIN"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.billingAddresspin}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.billingAddresspin && <Alert severity="error">{error.billingAddresspin}</Alert>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Shipping Address:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="shippingAddress1"
                                                                label="Shipping Address Line 1"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.shippingAddress1}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.shippingAddress1 && <Alert severity="error">{error.shippingAddress1}</Alert>}
                                                            <TextField
                                                                fullWidth
                                                                name="shippingAddress2"
                                                                label="Shipping Address Line 2"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.shippingAddress2}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.shippingAddress2 && <Alert severity="error">{error.shippingAddress2}</Alert>}
                                                            <TextField
                                                                fullWidth
                                                                name="shippingAddress3"
                                                                label="Shipping Address Line 3"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.shippingAddress3}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.shippingAddress3 && <Alert severity="error">{error.shippingAddress3}</Alert>}
                                                            <TextField
                                                                fullWidth
                                                                name="shippingAddresscity"
                                                                label="Shipping City"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.shippingAddresscity}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.shippingAddresscity && <Alert severity="error">{error.shippingAddresscity}</Alert>}
                                                            <TextField
                                                                fullWidth
                                                                name="shippingAddresspin"
                                                                label="ShippingPIN"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.shippingAddresspin}
                                                                onChange={handleChange}
                                                                className="form-field"
                                                            />
                                                            {error.shippingAddresspin && <Alert severity="error">{error.shippingAddresspin}</Alert>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Username:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="username"
                                                                label="Username"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.username}
                                                                onChange={handleChange}
                                                            />
                                                            {error.username && <Alert severity="error">{error.username}</Alert>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            {/* <label>Password:</label> */}
                                                            <TextField
                                                                fullWidth
                                                                name="password"
                                                                type={showPassword ? 'text' : 'password'}
                                                                label="Password"
                                                                variant="outlined"
                                                                size="small"
                                                                color="warning"
                                                                value={formData.password}
                                                                onChange={handleChange}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={togglePasswordVisibility}
                                                                                edge="end"
                                                                            >
                                                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            {error.password && <Alert severity="error">{error.password}</Alert>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="primary-button custom-button">
                                                                <Button sx={{
                                                                    mt: 1,
                                                                    background: "linear-gradient(90deg, #00c6ff, #0072ff)",
                                                                    '&:hover': { background: "linear-gradient(90deg, #0072ff, #00c6ff)" },
                                                                    color: "#fff",
                                                                }} type="submit" id="form-submit" className="btn" style={{ marginRight: '10px' }}>&nbsp;&nbsp;<b>Register</b>&nbsp;&nbsp;</Button><br></br><br></br>
                                                                <Link to="/Loginuser" style={{ textDecoration: "none" }}>&nbsp;&nbsp;<b>Existing User? Log in</b>&nbsp;&nbsp;</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                            {/* Display overall registration error */}
                                            {error.message && <Alert severity="error">{error.message}</Alert>}
                                            {/* Display success message */}
                                            {successMessage && <Alert severity="success">{successMessage}</Alert>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
        </div>
    );
}


import React from 'react';
import images from './imageloader.js';

const ProductImage = ({ imagePath }) => {
  const imageSrc = images[imagePath];
  // console.log(images)

  return (
    <img
      className="rounded-circle img-fluid border"
      src={imageSrc}
      alt="Product"
      style={{ width: '150px', height: '150px' }} // Adjust styling as needed
    />
  );
};

export default ProductImage;
